import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {withStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import TransitionGroup from 'react-transition-group/TransitionGroup';
import CSSTransition from 'react-transition-group/CSSTransition';

// END GAME.
import Sidebar from '../../components/Navigation/Sidebar/Sidebar';
import Header from '../../components/Navigation/Header/Header';
import Toolbar from '../../components/Navigation/Header/Toolbar';
import Alert from '../../components/UI/Alert/Alert';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import styles from './styles';
import {connect} from "react-redux";
import moment from 'moment'
import {withRouter} from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import {
    removeAPIError,
    removeAPISuccess,
    toggleSideDrawer
} from "../../store/actions";
import ReactPlayer from 'react-player'

import Button from '@material-ui/core/Button';
import AddIcon from '@material-ui/icons/Close';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

class Layout extends React.Component {
    state = {
        openTutorial: sessionStorage.getItem("openTutorial") !== 'false',
        showAgain: true
    };

    handleDrawerOpen = () => {
        this.setState({open: true});
    };

    handleDrawerClose = () => {
        this.setState({open: false});
    };

    renderErrorNotifications = () => {
        return (
            this.props.apiErrors.map((apiError, idx) => (
                <CSSTransition
                    key={idx}
                    classNames="fade"
                    timeout={3000}
                    onEntered={() => (
                        setTimeout(() => {
                            this.props.removeAPIError(apiError.name)
                        }, 3000)
                    )}
                >
                    <Alert
                        type='error'
                        idx={idx}
                        clickedHandler={() => this.props.removeAPIError(apiError.name)}
                    >
                        {apiError.name}:{apiError.msg}
                    </Alert>
                </CSSTransition>
            ))
        )
    };

    renderSuccessNotifications = () => {
        return (
            this.props.apiSuccesses.map((apiSuccess, idx) => (
                <CSSTransition
                    key={idx}
                    classNames="fade"
                    timeout={3000}
                    onEntered={() => (
                        setTimeout(() => {
                            this.props.removeAPISuccess(apiSuccess.name)
                        }, 3000)
                    )}
                >
                    <Alert
                        type='success'
                        idx={idx}
                        clickedHandler={() => this.props.removeAPISuccess(apiSuccess.name)}
                    >
                        {apiSuccess.name}:{apiSuccess.msg}
                    </Alert>
                </CSSTransition>
            ))
        )
    };

    renderNotifications() {
        return (
            <TransitionGroup component="span">
                {this.renderErrorNotifications()}
                {this.renderSuccessNotifications()}
            </TransitionGroup>
        );
    }

    handleClose = () => {
        this.setState({openTutorial: !this.state.openTutorial});
    }
    handleChange = (e) => {
        let showAgain = e.target.checked
        this.setState({showAgain}, () => sessionStorage.setItem("openTutorial", showAgain));
    }

    render() {
        console.log(sessionStorage.getItem("openTutorial"), this.state.openTutorial)
        const {classes} = this.props;

        let display = this.props.children;

        if (this.props.authenticated && this.props.location.pathname !== '/user-manual' && this.props.location.pathname !== '/useful-links') {

            display = (
                <div className={classes.root} style={{height: "100vh"}}>
                    <CssBaseline/>
                    <Header
                        classNames={classNames}
                        pageTitle={this.props.pageTitle}
                        classes={classes}
                        openOld={this.state.open}
                        open={this.props.sideDrawerToggled}
                        handleDrawerOpenOld={this.handleDrawerOpen}
                        handleDrawerOpen={this.props.toggleSideDrawer}
                    />

                    <Sidebar
                        handleDrawerCloseOld={this.handleDrawerClose}
                        handleDrawerClose={this.props.toggleSideDrawer}
                        classNames={classNames}
                        classes={classes}
                        open={this.props.sideDrawerToggled}
                        userDetails={this.props.userDetails}
                        isChildManagement={this.props.isChildManagement}
                    />

                    <main className={classes.content} style={{paddingTop: "66px"}}>
                        <Toolbar
                            classNames={classNames}
                            pageTitle={this.props.pageTitle}
                            classes={classes}
                            openOld={this.state.open}
                            open={this.props.sideDrawerToggled}
                            handleDrawerOpenOld={this.handleDrawerOpen}
                            handleDrawerOpen={this.props.toggleSideDrawer}
                        />
                        {this.props.userDetails.location
                        && this.props.userDetails.location.length > 0
                        && this.props.pageTitle !== "My Portfolio Report" ?
                            this.props.userDetails.location.map(location => {
                                if (location.payment_status === "Over Due" || location.payment_status === "Suspended") {
                                    let message = location.payment_status === "Over Due" ?
                                        'Location(' + location.name + ') will be suspended on ' + moment(location.suspension_date).format("LLLL") :
                                        'Location(' + location.name + ') was suspended '
                                    return (<React.Fragment>
                                        <br/>
                                        <Paper style={{paddingLeft: "20px"}}>
                                            <TextField
                                                value={message}
                                                margin="normal"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    readOnly: true
                                                }}
                                                style={{width: "100%"}}
                                                multiline={true}
                                                label={location.payment_status === "Over Due" ? "Account Overdue:" : "Account Suspended:"}
                                            />
                                        </Paper>
                                    </React.Fragment>)
                                }
                            })
                            : null}

                        {this.props.userDetails.company
                        && this.props.userDetails.company.site_wide_message
                        && this.props.userDetails.company.site_wide_message.trim().length > 0
                        && this.props.pageTitle !== "Managing Company"
                        && this.props.pageTitle !== "Profile" ?

                            <React.Fragment>
                                <br/>
                                <Paper style={{paddingLeft: "20px"}}>
                                    <TextField
                                        value={this.props.userDetails.company.site_wide_message}
                                        margin="normal"
                                        InputProps={{
                                            disableUnderline: true,
                                            readOnly: true
                                        }}
                                        style={{width: "100%"}}
                                        multiline={true}
                                        label={"Site Wide Message:"}
                                    />
                                </Paper>
                            </React.Fragment> : null}
                        <div className={classes.tableContainer}>
                            <br/>

                            {
                                this.props.authenticated !== false
                                    ? this.renderNotifications()
                                    : null
                            }

                            {this.props.children}
                        </div>

                        <Modal
                            aria-labelledby="simple-modal-title"
                            aria-describedby="simple-modal-description"
                            open={this.state.openTutorial}
                            onClose={this.handleClose.bind(this)}
                        >
                            <Paper style={{
                                width: '75vw',
                                height: "70vh",
                                top: "50%",
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "white",
                                position: "absolute",
                                border: "1px solid black",
                                paddingLeft: "10px",
                                paddingRight: "10px",
                                paddingTop: "10px",
                                paddingBottom: "85px"
                            }}>
                                <AddIcon style={{float: 'right', top: 0}} onClick={this.handleClose.bind(this)}/>
                                <ReactPlayer url='https://www.youtube.com/watch?v=TT7-Tk1x_cY' loop controls
                                             width='100%'
                                             height='100%'
                                />
                                <br/>
                                <br/>
                                <Button variant="contained" style={{float: 'right'}}
                                        onClick={this.handleClose.bind(this)}>
                                    Skip Tutorial
                                </Button>
                                <FormControlLabel style={{float: 'right'}}
                                                  control={
                                                      <Checkbox checked={this.state.showAgain}
                                                                onChange={this.handleChange.bind(this)}
                                                                value='showAgain'/>
                                                  }
                                                  label="show tutorial next login"
                                />
                            </Paper>
                        </Modal>
                    </main>
                </div>
            );
        }

        return display;
    }
}

Layout.propTypes = {
    classes: PropTypes.object.isRequired,
    authenticated: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool
    ]),
};

// You will die
const mapStateToProps = state => {
    return {
        pageTitle: state.ui.pageTitle,
        isAuthenticated: state.auth.isAuthenticated,
        token: state.auth.token,
        checkedToken: state.auth.checkedToken,
        apiErrors: state.apiError.errors,
        apiSuccesses: state.apiError.successes,
        sideDrawerToggled: state.ui.sideDrawerToggled,
        userDetails: state.auth.userDetails,
        isChildManagement: state.auth.userDetails.company ? state.auth.userDetails.company.is_child_management : 0
    }
};

const mapDispatchToProps = dispatch => {
    return {
        removeAPIError: (index) => dispatch(removeAPIError(index)),
        removeAPISuccess: (index) => dispatch(removeAPISuccess(index)),
        toggleSideDrawer: () => dispatch(toggleSideDrawer())
    }
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Layout)));
