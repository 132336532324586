export const AUTHENTICATE = 'AUTHENTICATE';
export const AUTH_START = 'AUTH_START';
export const AUTH_END = 'AUTH_END';
export const AUTH_FAIL = 'AUTH_FAIL';

export const SET_AUTH_INITIAL_VALUES = 'SET_AUTH_INITIAL_VALUES';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_USER_DETAILS = 'SET_USER_DETAILS';
export const UPDATE_SITE_WIDE_MESSAGE = 'UPDATE_SITE_WIDE_MESSAGE';
export const CHECKED_TOKEN = 'CHECKED_TOKEN';
export const LOGOUT = 'LOGOUT';

export const USER_SET_MANAGEMENT_COMPANIES = 'USER_SET_MANAGEMENT_COMPANIES';
export const USER_SET_ACCOUNT_TYPES = 'USER_SET_ACCOUNT_TYPES';
export const USER_SET_USER_DETAILS = 'USER_SET_USER_DETAILS';
export const USER_SET_USER_FORM_TYPE = 'USER_SET_USER_FORM_TYPE';


export const USER_UPDATE_FORM_HANDLER = 'USER_UPDATE_FORM_HANDLER';
export const COMPANY_UPDATE_FORM_HANDLER = 'COMPANY_UPDATE_FORM_HANDLER';

export const ADD_API_ERROR = 'ADD_API_ERROR';
export const REMOVE_API_ERROR = 'REMOVE_API_ERROR';

export const ADD_API_SUCCESS = 'ADD_API_SUCCESS';
export const REMOVE_API_SUCCESS = 'REMOVE_API_SUCCESS';

export const SET_USERS = 'SET_USERS';

export const RESET_MANAGE_FORM_FIELDS = 'RESET_MANAGE_FORM_FIELDS';
export const USER_FORM_CHANGE_MODE = 'USER_FORM_CHANGE_MODE';

// ===============================================
// UI
// ===============================================
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const TOGGLE_SIDE_DRAWER = 'TOGGLE_SIDE_DRAWER';

// ===============================================
// Company Actions
// ===============================================

export const SET_COMPANIES = 'SET_COMPANIES';
export const SET_COMPANY_FORM_TYPE = 'SET_COMPANY_FORM_TYPE';
export const SET_COMPANY_DETAILS = 'SET_COMPANY_DETAILS';
export const SET_ASSIGN_MODE = 'SET_ASSIGN_MODE';
export const RESET_COMPANY_FORM_FIELDS = 'RESET_COMPANY_FORM_FIELDS';

export const SET_MANAGED_COMPANY = 'SET_MANAGED_COMPANY';
export const SET_ASSIGNABLE_USERS = 'SET_ASSIGNABLE_USERS';
export const SET_MANAGED_USERS_AND_COMPANY = 'SET_MANAGED_USERS_AND_COMPANY';

// ===============================================
// Location Actions
// ===============================================

export const SET_LOCATIONS = 'SET_LOCATIONS';
export const SET_HAS_COMPANIES = 'SET_HAS_COMPANIES';
export const SET_LOCATIONS_AND_COMPANY_TYPES = 'SET_LOCATIONS_AND_COMPANY_TYPES';
export const SET_OVERDUE_LOCATIONS = 'SET_OVERDUE_LOCATIONS';
export const SET_INVOICE_LOCATIONS = 'SET_INVOICE_LOCATIONS'

export const REMOVE_API_ERROR_ALL = 'REMOVE_API_ERROR_ALL';
export const LOCATION_UPDATE_FORM_HANDLER = 'LOCATION_UPDATE_FORM_HANDLER';
export const INVOICE_LOCATION_UPDATE_FORM_HANDLER = 'INVOICE_LOCATION_UPDATE_FORM_HANDLER'
export const RESET_MANAGE_LOCATION_FORM_FIELDS = 'RESET_MANAGE_LOCATION_FORM_FIELDS';
export const RESET_MANAGE_INVOICE_LOCATION_FORM_FIELDS = 'RESET_MANAGE_INVOICE_LOCATION_FORM_FIELDS'
export const LOCATION_SET_USER_FORM_TYPE = 'LOCATION_SET_USER_FORM_TYPE';
export const SET_LOCATION_TYPES = 'SET_LOCATION_TYPES';
export const SET_COMPANIES_TYPES = 'SET_COMPANIES_TYPES';
export const SET_LOCATION_DETAILS = 'SET_LOCATION_DETAILS';
export const SET_INVOICE_LOCATION_DETAILS = 'SET_INVOICE_LOCATION_DETAILS'
export const UPDATE_LOCATION_COMPANY_TYPE_FILTER = 'UPDATE_LOCATION_COMPANY_TYPE_FILTER';
export const SET_STATES = 'SET_STATES';
export const SET_POSTCODES = 'SET_POSTCODES';

// ===============================================
// Spaces Actions
// ===============================================

export const SET_SPACES = 'SET_SPACES';
export const SET_SPACE = 'SET_SPACE';
export const SET_SPACE_LOCATIONS = 'SET_SPACE_LOCATIONS';
export const SET_SPACE_AND_LOCATION_FILTERS = 'SET_SPACE_AND_LOCATION_FILTERS';
export const SET_LOCATIONS_AND_SPACE_DETAILS = 'SET_LOCATIONS_AND_SPACE_DETAILS';
export const RESET_MANAGE_SPACES_FORM = 'RESET_MANAGE_SPACES_FORM';
export const SPACE_UPDATE_FORM_HANDLER = 'SPACE_UPDATE_FORM_HANDLER';

// ===============================================
// Spaces Actions
// ===============================================

export const SET_COMPLIANCES = 'SET_COMPLIANCES';
export const SET_SPACE_ID_COMPLIANCES = 'SET_SPACE_ID_COMPLIANCES';
export const RESET_COMPLIANCE_FORM_FIELDS = 'RESET_COMPLIANCE_FORM_FIELDS';
export const SET_COMPLIANCE_FILTERS = 'SET_COMPLIANCE_FILTERS';
export const SET_COMPLIANCE_CATEGORIES = 'SET_COMPLIANCE_CATEGORIES';
export const SET_COMPLIANCE_MEASURES = 'SET_COMPLIANCE_MEASURES';
export const SET_COMPLIANCE_FORM_DETAILS= 'SET_COMPLIANCE_FORM_DETAILS';
export const COMPLIANCE_UPDATE_FORM_HANDLER= 'COMPLIANCE_UPDATE_FORM_HANDLER';
export const SET_FILTERED_LOCATIONS= 'SET_FILTERED_LOCATIONS';
export const SET_FILTERED_SPACES= 'SET_FILTERED_SPACES';
export const SET_FILTERED_MEASURES= 'SET_FILTERED_MEASURES';
export const SET_COMPLIANCE_MEASURE_OPTIONS= 'SET_COMPLIANCE_MEASURE_OPTIONS';
export const START_LOADING_CATEGORY_OPTIONS= 'START_LOADING_CATEGORY_OPTIONS';
export const STOP_LOADING_CATEGORY_OPTIONS= 'STOP_LOADING_CATEGORY_OPTIONS';

// ===============================================
// Archives
// ===============================================
export const SET_ARCHIVES = 'SET_ARCHIVES';